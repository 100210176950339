export enum User_Role {
None = 'none',
Default = 'DEFAULT_ROLE',
Unkwnown = 'UNKNOWN_ROLE',
Candidate = 'APPLICANT_INTERN',
Employer = 'INTERNSHIP_ADMINISTRATOR',
Recruiter = 'INTERNSHIP_ADMINISTRATOR',
Admin = '',
Marketer = '',
ContentManager = ''
}

export enum AuthMode {  
    CustomServer = 'Custom Server',   
}